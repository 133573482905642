export const Tientech_CaseInit = params => {
  try {
    if (params.sys === undefined) {
      params.sys = {}
    }
    if (params.case_info !== undefined) {
      // name
      params.sys.name = params.case_info.name
      // area
      if (params.case_info.area === '' || params.case_info.area === undefined) {
        params.sys.area = ''
      } else {
        params.sys.area = params.case_info.area
      }
      // county
      if (
        params.case_info.county === '' ||
        params.case_info.county === undefined
      ) {
        params.sys.county = ''
      } else {
        params.sys.county = params.case_info.county
      }
      // location
      params.sys.location = 'unknown'
      if (params.sys.area === '' && params.sys.county === '') {
        params.sys.location = 'unknown'
      } else if (params.sys.area === 'tw') {
        params.sys.location = params.sys.county
      } else {
        params.sys.location = `${params.sys.area}-${params.sys.county}`
      }
      // township
      params.sys.township = 'unknown'
      if (params.case_info.township === '') {
        params.sys.township = 'unknown'
      } else {
        params.sys.township = `${params.case_info.township}`
      }

      // dateofusing
      params.sys.dateofusing = params.case_info.createtime
      if (
        params.case_info.dateofusing !== undefined &&
        params.case_info.dateofusing !== null &&
        params.case_info.dateofusing !== ''
      ) {
        params.sys.dateofusing = params.case_info.dateofusing
      }
      if (
        params.sys.dateofusing === undefined ||
        params.sys.dateofusing === null
      ) {
        params.sys.dateofusing = ''
      }
      // dateofusing
      params.sys.status = 'unknown'
      if (params.case_status !== undefined) {
        if (
          params.case_status.barn !== undefined &&
          params.case_status.barn !== null &&
          params.case_status.barn !== ''
        ) {
          params.sys.status = params.case_status.barn
        }
      }
      // params.sys.typebgimg = image_bg_unknown
      params.sys.type = params.case_info.type
    }
    params.sys.health_status = '-'
    params.sys.health_status_value = 1
    if (params.sys.type === undefined) {
      params.sys.type = []
    }
    return params
  } catch (error) {
    console.log(error)
    return null
  }
}

const device_organizedata = (
  tmp_deviceRegionCalculator,
  dataexpiredate,
  case_devices,
  devicetype,
  region_key,
  region_device_key,
  devicedata,
  case_info,
  region_device_name
) => {
  try {
    let deviceRegionCalculator = tmp_deviceRegionCalculator
    if (deviceRegionCalculator === undefined) {
      deviceRegionCalculator = {}
    }
    if (deviceRegionCalculator[region_key] === undefined) {
      deviceRegionCalculator[region_key] = {}
    }
    if (deviceRegionCalculator[region_key][devicetype] === undefined) {
      deviceRegionCalculator[region_key][devicetype] = {
        name: null,
        average_value: null,
        devices: {},
        unit: '',
        counter: 0,
      }
    }
    const showsummarydata = {}
    const showdetaildata = {}
    let targetvalue = {}
    let devicestatus = {}
    let deviceupdatetime
    Object.keys(case_devices[region_key][region_device_key].devicelist).forEach(
      smcpn => {
        Object.keys(
          case_devices[region_key][region_device_key].devicelist[smcpn]
        ).forEach(devicepn => {
          if (devicedata[smcpn] === undefined) {
            return
          }
          if (devicedata[smcpn].devices === undefined) {
            return
          }
          if (devicedata[smcpn].devices[devicepn] === undefined) {
            return
          }
          if (showsummarydata[smcpn] === undefined) {
            showsummarydata[smcpn] = {}
          }
          if (showsummarydata[smcpn][devicepn] === undefined) {
            showsummarydata[smcpn][devicepn] = {}
          }
          if (showsummarydata[smcpn][devicepn].data === undefined) {
            showsummarydata[smcpn][devicepn].data = {}
          }
          if (showdetaildata[smcpn] === undefined) {
            showdetaildata[smcpn] = {}
          }
          if (showdetaildata[smcpn][devicepn] === undefined) {
            showdetaildata[smcpn][devicepn] = {}
          }
          if (
            deviceRegionCalculator[region_key][devicetype].devices === undefined
          ) {
            deviceRegionCalculator[region_key][devicetype].devices = {}
          }
          if (
            deviceRegionCalculator[region_key][devicetype].devices[
              `${region_device_key}_${smcpn}_${devicepn}`
            ] === undefined
          ) {
            deviceRegionCalculator[region_key][devicetype].devices[
              `${region_device_key}_${smcpn}_${devicepn}`
            ] = {}
          }
          deviceRegionCalculator[region_key][devicetype].devices[
            `${region_device_key}_${smcpn}_${devicepn}`
          ].name = case_devices[region_key][region_device_key].name
          deviceRegionCalculator[region_key][devicetype].devices[
            `${region_device_key}_${smcpn}_${devicepn}`
          ].regiondevicename = region_device_name
          deviceRegionCalculator[region_key][devicetype].devices[
            `${region_device_key}_${smcpn}_${devicepn}`
          ].smcpn = smcpn
          deviceRegionCalculator[region_key][devicetype].devices[
            `${region_device_key}_${smcpn}_${devicepn}`
          ].devicepn = devicepn
          if (
            devicedata[smcpn].devices[devicepn].latest_rawdata === undefined
          ) {
            return
          }
          const tmp_data = devicedata[smcpn].devices[devicepn].latest_rawdata
          Object.keys(tmp_data.data).forEach(datakey => {
            const tmp_obj = {}
            tmp_obj.unit =
              datakey.split('#').length > 1 ? datakey.split('#')[1] : ''
            tmp_obj.name = datakey.split('_')[0]
            tmp_obj.number = ''
            if (datakey.split('_').length > 1) {
              if (datakey.split('_')[1].split('#').length > 1) {
                tmp_obj.number = datakey.split('_')[1].split('#')[0]
              }
            }
            tmp_obj.value = tmp_data.data[datakey]
            tmp_obj.datetime = tmp_data.datetime
            if (showdetaildata[smcpn][devicepn][datakey] === undefined) {
              showdetaildata[smcpn][devicepn][datakey] = {}
            }
            if (showdetaildata[smcpn][devicepn][datakey].data === undefined) {
              showdetaildata[smcpn][devicepn][datakey].data = {}
            }
            showdetaildata[smcpn][devicepn][datakey].data = tmp_obj
          })
          switch (devicetype) {
            case 'thermometer':
            case 'thermometer_outside':
              showsummarydata[smcpn][devicepn] = {
                datetime: 0,
                data: {
                  thermometer: {
                    name: window.tientech.i18n.messages[
                      window.tientech.i18n.locale
                    ].common.temperature,
                    unit: '',
                    number: '',
                    value:
                      window.tientech.i18n.messages[window.tientech.i18n.locale]
                        .common.unknown,
                  },
                },
              }
              if (tmp_data.replace !== undefined) {
                if (tmp_data.data[tmp_data.replace] !== undefined) {
                  showsummarydata[smcpn][devicepn].datetime = tmp_data.datetime
                  deviceupdatetime = tmp_data.datetime
                  targetvalue = parseFloat(
                    Number(parseFloat(tmp_data.data[tmp_data.replace])).toFixed(
                      2
                    )
                  )
                  showsummarydata[smcpn][devicepn].data.thermometer = {
                    unit:
                      tmp_data.replace.split('#').length > 1
                        ? `°${tmp_data.replace.split('#')[1]}`
                        : '',
                    name: window.tientech.i18n.messages[
                      window.tientech.i18n.locale
                    ].common.temperature,
                    number: '',
                    value: targetvalue,
                  }
                  deviceRegionCalculator[region_key][devicetype].devices[
                    `${region_device_key}_${smcpn}_${devicepn}`
                  ].datetime = deviceupdatetime
                  deviceRegionCalculator[region_key][devicetype].devices[
                    `${region_device_key}_${smcpn}_${devicepn}`
                  ].value = targetvalue
                  deviceRegionCalculator[region_key][devicetype].unit =
                    tmp_data.replace.split('#').length > 1
                      ? `°${tmp_data.replace.split('#')[1]}`
                      : ''
                }
              }
              break
            case 'hygrometer':
            case 'hygrometer_outside':
              showsummarydata[smcpn][devicepn] = {
                datetime: 0,
                data: {
                  hygrometer: {
                    name: window.tientech.i18n.messages[
                      window.tientech.i18n.locale
                    ].common.humidity,
                    unit: '',
                    number: '',
                    value:
                      window.tientech.i18n.messages[window.tientech.i18n.locale]
                        .common.unknown,
                  },
                },
              }
              if (tmp_data.replace !== undefined) {
                if (tmp_data.data[tmp_data.replace] !== undefined) {
                  showsummarydata[smcpn][devicepn].datetime = tmp_data.datetime
                  deviceupdatetime = tmp_data.datetime
                  targetvalue = parseFloat(
                    Number(parseFloat(tmp_data.data[tmp_data.replace])).toFixed(
                      2
                    )
                  )
                  showsummarydata[smcpn][devicepn].data.hygrometer = {
                    unit: tmp_data.replace.split('#')[1],
                    name: window.tientech.i18n.messages[
                      window.tientech.i18n.locale
                    ].common.humidity,
                    number: '',
                    value: targetvalue,
                  }
                  deviceRegionCalculator[region_key][devicetype].devices[
                    `${region_device_key}_${smcpn}_${devicepn}`
                  ].datetime = deviceupdatetime
                  deviceRegionCalculator[region_key][devicetype].devices[
                    `${region_device_key}_${smcpn}_${devicepn}`
                  ].value = targetvalue
                  deviceRegionCalculator[region_key][devicetype].unit =
                    tmp_data.replace.split('#')[1]
                }
              }
              break
            case 'carbondioxide':
            case 'carbondioxide_outside':
              showsummarydata[smcpn][devicepn] = {
                datetime: 0,
                data: {
                  hygrometer: {
                    name: window.tientech.i18n.messages[
                      window.tientech.i18n.locale
                    ].common.carbondioxide,
                    unit: 'ppm',
                    number: '',
                    value:
                      window.tientech.i18n.messages[window.tientech.i18n.locale]
                        .common.unknown,
                  },
                },
              }
              if (tmp_data.replace !== undefined) {
                if (tmp_data.data[tmp_data.replace] !== undefined) {
                  showsummarydata[smcpn][devicepn].datetime = tmp_data.datetime
                  deviceupdatetime = tmp_data.datetime
                  targetvalue = parseFloat(
                    Number(parseFloat(tmp_data.data[tmp_data.replace])).toFixed(
                      0
                    )
                  )
                  showsummarydata[smcpn][devicepn].data.carbondioxide = {
                    unit: tmp_data.replace.split('#')[1],
                    name: window.tientech.i18n.messages[
                      window.tientech.i18n.locale
                    ].common.carbondioxide,
                    number: '',
                    value: targetvalue,
                  }
                  deviceRegionCalculator[region_key][devicetype].devices[
                    `${region_device_key}_${smcpn}_${devicepn}`
                  ].datetime = deviceupdatetime
                  deviceRegionCalculator[region_key][devicetype].devices[
                    `${region_device_key}_${smcpn}_${devicepn}`
                  ].value = targetvalue
                  deviceRegionCalculator[region_key][devicetype].unit =
                    tmp_data.replace.split('#')[1]
                }
              }
              break
            case 'ammonia':
            case 'ammonia_outside':
              showsummarydata[smcpn][devicepn] = {
                datetime: 0,
                data: {
                  hygrometer: {
                    name: window.tientech.i18n.messages[
                      window.tientech.i18n.locale
                    ].common.ammonia,
                    unit: 'ppm',
                    number: '',
                    value:
                      window.tientech.i18n.messages[window.tientech.i18n.locale]
                        .common.unknown,
                  },
                },
              }
              if (tmp_data.replace !== undefined) {
                if (tmp_data.data[tmp_data.replace] !== undefined) {
                  showsummarydata[smcpn][devicepn].datetime = tmp_data.datetime
                  deviceupdatetime = tmp_data.datetime
                  targetvalue = parseFloat(
                    Number(parseFloat(tmp_data.data[tmp_data.replace])).toFixed(
                      0
                    )
                  )
                  showsummarydata[smcpn][devicepn].data.ammonia = {
                    unit: tmp_data.replace.split('#')[1],
                    name: window.tientech.i18n.messages[
                      window.tientech.i18n.locale
                    ].common.ammonia,
                    number: '',
                    value: targetvalue,
                  }
                  deviceRegionCalculator[region_key][devicetype].devices[
                    `${region_device_key}_${smcpn}_${devicepn}`
                  ].datetime = deviceupdatetime
                  deviceRegionCalculator[region_key][devicetype].devices[
                    `${region_device_key}_${smcpn}_${devicepn}`
                  ].value = targetvalue
                  deviceRegionCalculator[region_key][devicetype].unit =
                    tmp_data.replace.split('#')[1]
                }
              }
              break
            case 'generator_diesel':
              showsummarydata[smcpn][devicepn] = {
                datetime: 0,
                data: {
                  chargevoltage: {
                    name: window.tientech.i18n.messages[
                      window.tientech.i18n.locale
                    ].common.voltage,
                    unit: 'V',
                    number: '',
                    value:
                      window.tientech.i18n.messages[window.tientech.i18n.locale]
                        .common.unknown,
                  },
                  oiltemperature: {
                    name: window.tientech.i18n.messages[
                      window.tientech.i18n.locale
                    ].common.temperature,
                    unit: '°C',
                    number: 0,
                    value:
                      window.tientech.i18n.messages[window.tientech.i18n.locale]
                        .common.unknown,
                  },
                  oilpressure: {
                    name:
                      window.tientech.i18n.messages[window.tientech.i18n.locale]
                        .common.oil +
                      window.tientech.i18n.messages[window.tientech.i18n.locale]
                        .common.pressure,
                    unit: 'bar',
                    number: 0,
                    value:
                      window.tientech.i18n.messages[window.tientech.i18n.locale]
                        .common.unknown,
                  },
                  'start count': {
                    name:
                      window.tientech.i18n.messages[window.tientech.i18n.locale]
                        .common.startup +
                      window.tientech.i18n.messages[window.tientech.i18n.locale]
                        .common.numberoftimes,
                    unit: 'number',
                    number: 0,
                    value:
                      window.tientech.i18n.messages[window.tientech.i18n.locale]
                        .common.unknown,
                  },
                  totalpower: {
                    name: window.tientech.i18n.messages[
                      window.tientech.i18n.locale
                    ].common.totalpower,
                    unit: 'kwh',
                    number: 0,
                    value:
                      window.tientech.i18n.messages[window.tientech.i18n.locale]
                        .common.unknown,
                  },
                  power: {
                    name: window.tientech.i18n.messages[
                      window.tientech.i18n.locale
                    ].common.power,
                    unit: 'kwh',
                    number: 0,
                    value:
                      window.tientech.i18n.messages[window.tientech.i18n.locale]
                        .common.unknown,
                  },
                },
              }
              targetvalue = {}
              deviceupdatetime = tmp_data.datetime
              showsummarydata[smcpn][devicepn].datetime = deviceupdatetime
              if (tmp_data.data !== undefined) {
                if (tmp_data.data['chargevoltage_1#V'] !== undefined) {
                  const datavalue = tmp_data.data['chargevoltage_1#V']
                  showsummarydata[smcpn][devicepn].data.chargevoltage.value =
                    datavalue
                  targetvalue.chargevoltage = datavalue
                  deviceRegionCalculator[region_key][devicetype].devices[
                    `${region_device_key}_${smcpn}_${devicepn}`
                  ].chargevoltage = {}
                  deviceRegionCalculator[region_key][devicetype].devices[
                    `${region_device_key}_${smcpn}_${devicepn}`
                  ].chargevoltage.value = datavalue
                  deviceRegionCalculator[region_key][devicetype].devices[
                    `${region_device_key}_${smcpn}_${devicepn}`
                  ].chargevoltage.uint = 'V'
                }

                if (tmp_data.data['oiltemperature#C'] !== undefined) {
                  const datavalue = tmp_data.data['oiltemperature#C']
                  showsummarydata[smcpn][devicepn].datetime = tmp_data.datetime
                  showsummarydata[smcpn][devicepn].data.oiltemperature.value =
                    datavalue
                  targetvalue.oiltemperature = datavalue
                  deviceRegionCalculator[region_key][devicetype].devices[
                    `${region_device_key}_${smcpn}_${devicepn}`
                  ].oiltemperature = {}
                  deviceRegionCalculator[region_key][devicetype].devices[
                    `${region_device_key}_${smcpn}_${devicepn}`
                  ].oiltemperature.value = datavalue
                  deviceRegionCalculator[region_key][devicetype].devices[
                    `${region_device_key}_${smcpn}_${devicepn}`
                  ].oiltemperature.uint = '°C'
                }
                if (tmp_data.data['oilpressure#bar'] !== undefined) {
                  const datavalue = tmp_data.data['oilpressure#bar']
                  showsummarydata[smcpn][devicepn].datetime = tmp_data.datetime
                  showsummarydata[smcpn][devicepn].data.oilpressure.value =
                    datavalue
                  targetvalue.oilpressure = datavalue
                  deviceRegionCalculator[region_key][devicetype].devices[
                    `${region_device_key}_${smcpn}_${devicepn}`
                  ].oilpressure = {}
                  deviceRegionCalculator[region_key][devicetype].devices[
                    `${region_device_key}_${smcpn}_${devicepn}`
                  ].oilpressure.value = datavalue
                  deviceRegionCalculator[region_key][devicetype].devices[
                    `${region_device_key}_${smcpn}_${devicepn}`
                  ].oilpressure.uint = 'bar'
                }
                if (tmp_data.data['start count'] !== undefined) {
                  const datavalue = tmp_data.data['start count']
                  showsummarydata[smcpn][devicepn].datetime = tmp_data.datetime
                  showsummarydata[smcpn][devicepn].data['start count'].value =
                    datavalue
                  targetvalue['start count'] = datavalue
                  deviceRegionCalculator[region_key][devicetype].devices[
                    `${region_device_key}_${smcpn}_${devicepn}`
                  ]['start count'] = {}
                  deviceRegionCalculator[region_key][devicetype].devices[
                    `${region_device_key}_${smcpn}_${devicepn}`
                  ]['start count'].value = datavalue
                }
                if (tmp_data.data['power#kw'] !== undefined) {
                  const datavalue = tmp_data.data['power#kw']
                  showsummarydata[smcpn][devicepn].datetime = tmp_data.datetime
                  showsummarydata[smcpn][devicepn].data.power.value = datavalue
                  targetvalue.power = datavalue
                  deviceRegionCalculator[region_key][devicetype].devices[
                    `${region_device_key}_${smcpn}_${devicepn}`
                  ].power = {}
                  deviceRegionCalculator[region_key][devicetype].devices[
                    `${region_device_key}_${smcpn}_${devicepn}`
                  ].power.value = datavalue
                  deviceRegionCalculator[region_key][devicetype].devices[
                    `${region_device_key}_${smcpn}_${devicepn}`
                  ].power.uint = 'kw'
                }
                if (tmp_data.data['totalpower#kwh'] !== undefined) {
                  const datavalue = tmp_data.data['totalpower#kwh']
                  showsummarydata[smcpn][devicepn].datetime = tmp_data.datetime
                  showsummarydata[smcpn][devicepn].data.totalpower.value =
                    datavalue
                  targetvalue.totalpower = datavalue
                  deviceRegionCalculator[region_key][devicetype].devices[
                    `${region_device_key}_${smcpn}_${devicepn}`
                  ].totalpower = {}
                  deviceRegionCalculator[region_key][devicetype].devices[
                    `${region_device_key}_${smcpn}_${devicepn}`
                  ].totalpower.value = datavalue
                  deviceRegionCalculator[region_key][devicetype].devices[
                    `${region_device_key}_${smcpn}_${devicepn}`
                  ].totalpower.uint = 'kwh'
                }
              }
              deviceRegionCalculator[region_key][devicetype].devices[
                `${region_device_key}_${smcpn}_${devicepn}`
              ].datetime = deviceupdatetime
              break
            case 'iocontroller':
              showsummarydata[smcpn][devicepn] = {}
              if (tmp_data.replace !== undefined) {
                if (tmp_data.data !== undefined) {
                  if (tmp_data.data[tmp_data.replace] !== undefined) {
                    let datavalue = tmp_data.data[tmp_data.replace]
                    if (datavalue === 1) {
                      datavalue =
                        window.tientech.i18n.messages[
                          window.tientech.i18n.locale
                        ].common.opened
                    } else {
                      datavalue =
                        window.tientech.i18n.messages[
                          window.tientech.i18n.locale
                        ].common.closed
                    }
                    showsummarydata[smcpn][devicepn].datetime =
                      tmp_data.datetime
                    deviceupdatetime = tmp_data.datetime
                    showsummarydata[smcpn][devicepn].data[
                      tmp_data.replace
                    ].value = datavalue
                  }
                }
              } else {
                Object.keys(tmp_data.data).forEach(key => {
                  const tmpArray1 = key.split('_')
                  let name = ''
                  let unit = ''
                  let number = ''
                  let value = ''
                  if (tmpArray1.length === 2) {
                    name = tmpArray1[0]
                    const tmpArray2 = tmpArray1[1].split('#')
                    if (tmpArray2.length === 1) {
                      number = tmpArray2[0]
                    } else if (tmpArray2.length === 2) {
                      number = tmpArray2[0]
                      unit = tmpArray2[1]
                    }
                  } else {
                    // 沒單位
                    const tmpArray2 = tmpArray1[1].split('#')
                    if (tmpArray2.length === 1) {
                      name = tmpArray1[0]
                    } else if (tmpArray2.length === 2) {
                      name = tmpArray2[0]
                      unit = tmpArray2[1]
                    }
                  }

                  if (
                    tmp_data.data[key] === undefined ||
                    tmp_data.data[key] === null
                  ) {
                    value = null
                    unit = ''
                  } else {
                    value = tmp_data.data[key]
                    if (!Number.isNaN(parseFloat(value))) {
                      value = parseFloat(value)
                    }
                  }
                  if (unit !== '') {
                    if (unit === 'C' || unit === 'F') {
                      unit = `°${unit}`
                    }
                  }

                  showsummarydata[smcpn][devicepn].datetime = tmp_data.datetime
                  deviceupdatetime = tmp_data.datetime
                  if (showsummarydata[smcpn][devicepn].data === undefined) {
                    showsummarydata[smcpn][devicepn].data = {}
                  }
                  showsummarydata[smcpn][devicepn].data[key] = {
                    name,
                    unit,
                    number,
                    value,
                  }
                })
              }

              break
            case 'inverter':
            case 'inverterfan':
              showsummarydata[smcpn][devicepn] = {
                datetime: 0,
                data: {
                  'Operational state': {
                    name: window.tientech.i18n.messages[
                      window.tientech.i18n.locale
                    ].common.status,
                    unit: '',
                    number: '',
                    value:
                      window.tientech.i18n.messages[window.tientech.i18n.locale]
                        .common.unknown,
                  },
                  'Output frequency#Hz': {
                    name: window.tientech.i18n.messages[
                      window.tientech.i18n.locale
                    ].common.frequency,
                    unit: 'Hz',
                    number: 0,
                    value:
                      window.tientech.i18n.messages[window.tientech.i18n.locale]
                        .common.unknown,
                  },
                  'Output frequency#%': {
                    name: window.tientech.i18n.messages[
                      window.tientech.i18n.locale
                    ].common.frequency,
                    unit: '%',
                    number: 0,
                    value:
                      window.tientech.i18n.messages[window.tientech.i18n.locale]
                        .common.unknown,
                  },
                },
              }
              targetvalue = 0
              devicestatus = '-'
              deviceupdatetime = tmp_data.datetime
              deviceRegionCalculator[region_key][devicetype].devices[
                `${region_device_key}_${smcpn}_${devicepn}`
              ].datetime = deviceupdatetime
              deviceRegionCalculator[region_key][devicetype].devices[
                `${region_device_key}_${smcpn}_${devicepn}`
              ].value = targetvalue
              deviceRegionCalculator[region_key][devicetype].devices[
                `${region_device_key}_${smcpn}_${devicepn}`
              ].devicestatus = '-'
              deviceRegionCalculator[region_key][devicetype].devices[
                `${region_device_key}_${smcpn}_${devicepn}`
              ].unit = '%'
              deviceRegionCalculator[region_key][devicetype].devices[
                `${region_device_key}_${smcpn}_${devicepn}`
              ].isrunning = 0
              deviceRegionCalculator[region_key][devicetype].unit = '%'
              if (new Date().getTime() - deviceupdatetime > dataexpiredate) {
                targetvalue = 0
              } else {
                if (tmp_data.data !== undefined) {
                  if (tmp_data.data['Output frequency#Hz'] !== undefined) {
                    let tmpfrequency = parseFloat(
                      tmp_data.data['Output frequency#Hz']
                    )
                    if (Number.isNaN(tmpfrequency)) {
                      showsummarydata[smcpn][devicepn].data[
                        'Output frequency#Hz'
                      ].value =
                        window.tientech.i18n.messages[
                          window.tientech.i18n.locale
                        ].common.unknown
                    } else {
                      if (tmpfrequency > 100) {
                        tmpfrequency = Number(
                          parseFloat(tmpfrequency / 10)
                        ).toFixed(1)
                      }
                      showsummarydata[smcpn][devicepn].data[
                        'Output frequency#Hz'
                      ].value = tmpfrequency
                      targetvalue = tmpfrequency
                      let user_area = 'tw'
                      if (case_info.area !== undefined) {
                        user_area = case_info.area
                      }
                      if (
                        user_area === undefined ||
                        user_area === 'tw' ||
                        user_area === 'ca' ||
                        user_area === 'us' ||
                        user_area === 'mx' ||
                        user_area === 'au' ||
                        user_area === 'nz' ||
                        user_area === 'pw' ||
                        user_area === 'gu' ||
                        user_area === 'kr'
                      ) {
                        targetvalue = Number(
                          parseFloat((tmpfrequency * 100) / 60)
                        ).toFixed(1)
                        showsummarydata[smcpn][devicepn].data[
                          'Output frequency#%'
                        ] = {
                          unit: '%',
                          name: window.tientech.i18n.messages[
                            window.tientech.i18n.locale
                          ].common.ratio,
                          number: '',
                          value: targetvalue,
                        }
                      } else {
                        targetvalue = Number(
                          parseFloat((tmpfrequency * 100) / 50)
                        ).toFixed(1)
                        if (targetvalue > 100) {
                          targetvalue = 100
                        }
                        showsummarydata[smcpn][devicepn].data[
                          'Output frequency#%'
                        ] = {
                          unit: '%',
                          name: window.tientech.i18n.messages[
                            window.tientech.i18n.locale
                          ].common.ratio,
                          number: '',
                          value: targetvalue,
                        }
                      }
                      deviceRegionCalculator[region_key][devicetype].devices[
                        `${region_device_key}_${smcpn}_${devicepn}`
                      ].value = targetvalue
                      deviceRegionCalculator[region_key][devicetype].devices[
                        `${region_device_key}_${smcpn}_${devicepn}`
                      ].frequency_value = tmpfrequency
                      deviceRegionCalculator[region_key][devicetype].devices[
                        `${region_device_key}_${smcpn}_${devicepn}`
                      ].datetime = deviceupdatetime
                      deviceRegionCalculator[region_key][devicetype].devices[
                        `${region_device_key}_${smcpn}_${devicepn}`
                      ].unit = '%'
                    }
                  }
                  if (tmp_data.data['Operational state'] !== undefined) {
                    let datavalue = tmp_data.data['Operational state']
                    if (datavalue === 1) {
                      datavalue =
                        window.tientech.i18n.messages[
                          window.tientech.i18n.locale
                        ].common.open
                    } else if (datavalue === 0) {
                      datavalue =
                        window.tientech.i18n.messages[
                          window.tientech.i18n.locale
                        ].common.close
                    } else {
                      datavalue =
                        window.tientech.i18n.messages[
                          window.tientech.i18n.locale
                        ].common.offline
                    }
                    showsummarydata[smcpn][devicepn].datetime =
                      tmp_data.datetime
                    showsummarydata[smcpn][devicepn].data[
                      'Operational state'
                    ].value = datavalue
                    deviceRegionCalculator[region_key][devicetype].devices[
                      `${region_device_key}_${smcpn}_${devicepn}`
                    ].devicestatus = datavalue
                    deviceRegionCalculator[region_key][devicetype].devices[
                      `${region_device_key}_${smcpn}_${devicepn}`
                    ].isrunning = tmp_data.data['Operational state']
                    devicestatus = datavalue
                  }
                }
              }
              break
            case 'fan':
              showsummarydata[smcpn][devicepn] = {
                datetime: 0,
                data: {
                  fan: {
                    name: window.tientech.i18n.messages[
                      window.tientech.i18n.locale
                    ].common.operatingstatus,
                    unit: '',
                    number: '',
                    value:
                      window.tientech.i18n.messages[window.tientech.i18n.locale]
                        .common.offline,
                  },
                },
              }
              targetvalue = undefined
              deviceupdatetime = tmp_data.datetime
              if (new Date().getTime() - deviceupdatetime > dataexpiredate) {
                targetvalue = 0
              } else {
                if (tmp_data.replace !== undefined) {
                  if (tmp_data.data !== undefined) {
                    if (tmp_data.data[tmp_data.replace] !== undefined) {
                      let datavalue = tmp_data.data[tmp_data.replace]
                      if (datavalue === 1) {
                        datavalue =
                          window.tientech.i18n.messages[
                            window.tientech.i18n.locale
                          ].common.opened
                      } else {
                        datavalue =
                          window.tientech.i18n.messages[
                            window.tientech.i18n.locale
                          ].common.closed
                      }
                      showsummarydata[smcpn][devicepn].datetime =
                        tmp_data.datetime
                      deviceupdatetime = tmp_data.datetime
                      showsummarydata[smcpn][devicepn].data.fan.value =
                        datavalue
                      devicestatus = datavalue
                      targetvalue = tmp_data.data[tmp_data.replace]
                      deviceRegionCalculator[region_key][devicetype].devices[
                        `${region_device_key}_${smcpn}_${devicepn}`
                      ].datetime = deviceupdatetime
                      deviceRegionCalculator[region_key][devicetype].devices[
                        `${region_device_key}_${smcpn}_${devicepn}`
                      ].value = targetvalue
                    }
                  }
                }
              }
              break
            case 'wetpad':
              showsummarydata[smcpn][devicepn] = {
                datetime: 0,
                data: {
                  fan: {
                    name: window.tientech.i18n.messages[
                      window.tientech.i18n.locale
                    ].common.operatingstatus,
                    unit: '',
                    number: '',
                    value:
                      window.tientech.i18n.messages[window.tientech.i18n.locale]
                        .common.offline,
                  },
                },
              }
              targetvalue = undefined
              deviceupdatetime = tmp_data.datetime
              if (new Date().getTime() - deviceupdatetime > dataexpiredate) {
                targetvalue = 0
              } else {
                if (tmp_data.replace !== undefined) {
                  if (tmp_data.data !== undefined) {
                    if (tmp_data.data[tmp_data.replace] !== undefined) {
                      let datavalue = tmp_data.data[tmp_data.replace]
                      if (datavalue === 1) {
                        datavalue =
                          window.tientech.i18n.messages[
                            window.tientech.i18n.locale
                          ].common.opened
                      } else {
                        datavalue =
                          window.tientech.i18n.messages[
                            window.tientech.i18n.locale
                          ].common.closed
                      }
                      showsummarydata[smcpn][devicepn].datetime =
                        tmp_data.datetime
                      deviceupdatetime = tmp_data.datetime
                      showsummarydata[smcpn][devicepn].data.fan.value =
                        datavalue
                      devicestatus = datavalue
                      targetvalue = tmp_data.data[tmp_data.replace]
                      deviceRegionCalculator[region_key][devicetype].devices[
                        `${region_device_key}_${smcpn}_${devicepn}`
                      ].datetime = deviceupdatetime
                      deviceRegionCalculator[region_key][devicetype].devices[
                        `${region_device_key}_${smcpn}_${devicepn}`
                      ].value = targetvalue
                    }
                  }
                }
              }
              break
            case 'ipcam_pigweigh':
              break
            default:
              break
          }
          deviceRegionCalculator[region_key][devicetype].counter += 1
        })
      }
    )
    const nowDate = new Date().getTime()
    let average_value = null
    deviceRegionCalculator[region_key][devicetype].enable_counter = 0
    Object.keys(deviceRegionCalculator[region_key][devicetype].devices).forEach(
      smpcpn_devicepn => {
        if (
          nowDate -
            deviceRegionCalculator[region_key][devicetype].devices[
              smpcpn_devicepn
            ].datetime <=
          dataexpiredate
        ) {
          if (
            deviceRegionCalculator[region_key][devicetype].devices[
              smpcpn_devicepn
            ].value !== undefined &&
            deviceRegionCalculator[region_key][devicetype].devices[
              smpcpn_devicepn
            ].value !== null &&
            Number.isNaN(
              deviceRegionCalculator[region_key][devicetype].devices[
                smpcpn_devicepn
              ].value
            ) !== true
          ) {
            let dotnumber = 0
            switch (devicetype) {
              case 'thermometer_outside':
              case 'thermometer':
              case 'hygrometer':
              case 'hygrometer_outside':
                dotnumber = 2
                if (average_value === null) {
                  average_value = Number(
                    deviceRegionCalculator[region_key][devicetype].devices[
                      smpcpn_devicepn
                    ].value
                  ).toFixed(dotnumber)
                } else {
                  average_value = Number(
                    (parseFloat(average_value) +
                      parseFloat(
                        deviceRegionCalculator[region_key][devicetype].devices[
                          smpcpn_devicepn
                        ].value
                      )) /
                      2
                  ).toFixed(dotnumber)
                }
                break
              case 'carbondioxide':
              case 'carbondioxide_outside':
              case 'ammonia':
              case 'ammonia_outside':
                dotnumber = 0
                if (average_value === null) {
                  average_value = Number(
                    deviceRegionCalculator[region_key][devicetype].devices[
                      smpcpn_devicepn
                    ].value
                  ).toFixed(dotnumber)
                } else {
                  average_value = Number(
                    (parseFloat(average_value) +
                      parseFloat(
                        deviceRegionCalculator[region_key][devicetype].devices[
                          smpcpn_devicepn
                        ].value
                      )) /
                      2
                  ).toFixed(dotnumber)
                }
                break
              case 'fan':
              case 'wetpad':
                dotnumber = 0
                if (average_value === null) {
                  average_value = Number(
                    deviceRegionCalculator[region_key][devicetype].devices[
                      smpcpn_devicepn
                    ].value
                  ).toFixed(dotnumber)
                } else {
                  average_value = Number(
                    (parseFloat(average_value) +
                      parseFloat(
                        deviceRegionCalculator[region_key][devicetype].devices[
                          smpcpn_devicepn
                        ].value
                      )) /
                      2
                  ).toFixed(dotnumber)
                }

                if (
                  deviceRegionCalculator[region_key][devicetype].devices[
                    smpcpn_devicepn
                  ].value === 1
                ) {
                  deviceRegionCalculator[region_key][
                    devicetype
                  ].enable_counter += 1
                }
                break
              case 'inverter':
              case 'inverterfan':
                dotnumber = 0
                if (average_value === null) {
                  average_value = Number(
                    deviceRegionCalculator[region_key][devicetype].devices[
                      smpcpn_devicepn
                    ].value
                  ).toFixed(dotnumber)
                } else {
                  average_value = Number(
                    (parseFloat(average_value) +
                      parseFloat(
                        deviceRegionCalculator[region_key][devicetype].devices[
                          smpcpn_devicepn
                        ].value
                      )) /
                      2
                  ).toFixed(dotnumber)
                }

                if (
                  deviceRegionCalculator[region_key][devicetype].devices[
                    smpcpn_devicepn
                  ].value === 1
                ) {
                  deviceRegionCalculator[region_key][
                    devicetype
                  ].enable_counter += 1
                }
                break
              default:
                break
            }
          }
        }
      }
    )
    deviceRegionCalculator[region_key][devicetype].average_value = average_value
    deviceRegionCalculator = { ...deviceRegionCalculator }
    return {
      deviceRegionCalculator,
      devicedata: {
        summarydata: showsummarydata,
        detaildata: showdetaildata,
        targetvalue,
        datetime: deviceupdatetime,
        status: devicestatus,
      },
    }
  } catch (error) {
    console.log(error)
    return []
  }
}
const device_organizedata_smc = (
  tmp_deviceRegionCalculator,
  case_devices,
  devicetype,
  region_key,
  region_device_key,
  devicedata,
  case_info,
  region_device_name
) => {
  try {
    let deviceRegionCalculator = tmp_deviceRegionCalculator
    if (deviceRegionCalculator === undefined) {
      deviceRegionCalculator = {}
    }
    if (deviceRegionCalculator[region_key] === undefined) {
      deviceRegionCalculator[region_key] = {}
    }
    if (deviceRegionCalculator[region_key][devicetype] === undefined) {
      deviceRegionCalculator[region_key][devicetype] = {
        name: null,
        average_value: null,
        devices: {},
        unit: '',
        counter: 0,
      }
    }
    const smcdevicelist = []
    Object.keys(case_devices[region_key][region_device_key].devicelist).forEach(
      smcpn => {
        if (devicedata[smcpn] === undefined) {
          return
        }
        let obj = {
          pn: smcpn,
          name: devicedata[smcpn].device_config.name,
        }
        obj = Object.assign(obj, devicedata[smcpn])

        deviceRegionCalculator[region_key][devicetype].devices[smcpn] = obj
        deviceRegionCalculator[region_key][devicetype].devices[
          smcpn
        ].regiondevicename = region_device_name
        smcdevicelist.push(obj)
        deviceRegionCalculator[region_key][devicetype].counter += 1
      }
    )
    return { deviceRegionCalculator, devicedata: smcdevicelist }
  } catch (error) {
    console.log(error)
    return []
  }
}
const device_organizedata_scale = (
  tmp_deviceRegionCalculator,
  case_devices,
  devicetype,
  region_key,
  region_device_key,
  devicedata,
  region_device_name
) => {
  try {
    let deviceRegionCalculator = tmp_deviceRegionCalculator
    if (deviceRegionCalculator === undefined) {
      deviceRegionCalculator = {}
    }
    if (deviceRegionCalculator[region_key] === undefined) {
      deviceRegionCalculator[region_key] = {}
    }
    if (deviceRegionCalculator[region_key][devicetype] === undefined) {
      deviceRegionCalculator[region_key][devicetype] = {
        name: null,
        average_value: null,
        devices: {},
        unit: '',
        counter: 0,
      }
    }
    const smcdevicelist = []
    Object.keys(case_devices[region_key][region_device_key].devicelist).forEach(
      smcpn => {
        if (devicedata[smcpn] === undefined) {
          return
        }
        let obj = {
          pn: smcpn,
          name: devicedata[smcpn].device_config.name,
        }
        obj = Object.assign(obj, devicedata[smcpn])
        deviceRegionCalculator[region_key][devicetype].devices[smcpn] = obj
        deviceRegionCalculator[region_key][devicetype].devices[
          smcpn
        ].regiondevicename = region_device_name
        smcdevicelist.push(obj)
        deviceRegionCalculator[region_key][devicetype].counter += 1
      }
    )
    return { deviceRegionCalculator, devicedata: smcdevicelist }
  } catch (error) {
    console.log(error)
    return []
  }
}
export const Tientech_CaseDeviceInit = params => {
  try {
    let deviceRegionCalculator = {}
    let showcategory_region = 'ALL'
    const regiondevicesArray = []
    const regiondeviceslist = {}
    if (params.case_devices !== undefined) {
      const case_devices = params.case_devices
      const case_device_data = params.device_data
      const case_info = params.case_info
      let tmpArray = []
      Object.keys(case_devices).forEach(region_key => {
        if (params.case_region === undefined) {
          return
        }
        const regionname = params.case_region[region_key].name
        if (regionname === undefined) {
          return
        }
        tmpArray.push({ regionname, region_key })
        deviceRegionCalculator[region_key] = {}
        Object.keys(case_devices[region_key]).forEach(region_device_key => {
          const region_device_name =
            case_devices[region_key][region_device_key].name
          const region_device_devicetype =
            case_devices[region_key][region_device_key].devicetype
          if (
            region_device_devicetype !== 'smc' &&
            region_device_devicetype !== 'scale'
          ) {
            const tmp_device_organizedata = device_organizedata(
              deviceRegionCalculator,
              1.5 * 60 * 1000,
              case_devices,
              region_device_devicetype,
              region_key,
              region_device_key,
              case_device_data,
              case_info,
              region_device_name
            )
            deviceRegionCalculator = {
              ...tmp_device_organizedata.deviceRegionCalculator,
            }
            const region_devicelist = tmp_device_organizedata.devicedata
            regiondevicesArray.push({
              region_device_key,
              region_key,
              region_device_devicetype,
              region_device_name,
              region_devicelist,
            })
          } else if (region_device_devicetype === 'smc') {
            const tmp_device_organizedata_smc = device_organizedata_smc(
              deviceRegionCalculator,
              case_devices,
              region_device_devicetype,
              region_key,
              region_device_key,
              case_device_data,
              region_device_name
            )
            deviceRegionCalculator = {
              ...tmp_device_organizedata_smc.deviceRegionCalculator,
            }
            const region_devicelist = tmp_device_organizedata_smc.devicedata

            regiondevicesArray.push({
              region_device_key,
              region_key,
              region_device_devicetype,
              region_device_name,
              region_devicelist,
            })
          } else if (region_device_devicetype === 'scale') {
            const tmp_device_organizedata_scale = device_organizedata_scale(
              deviceRegionCalculator,
              case_devices,
              region_device_devicetype,
              region_key,
              region_device_key,
              case_device_data,
              region_device_name
            )
            deviceRegionCalculator = {
              ...tmp_device_organizedata_scale.deviceRegionCalculator,
            }
            const region_devicelist = tmp_device_organizedata_scale.devicedata

            regiondevicesArray.push({
              region_device_key,
              region_key,
              region_device_devicetype,
              region_device_name,
              region_devicelist,
            })
          }
        })
      })
      if (tmpArray.length > 1) {
        tmpArray = tmpArray.sort((a, b) => {
          if (a.regionname < b.regionname) {
            return -1
          }
          if (a.regionname > b.regionname) {
            return 1
          }
          return 0
        })
      }
      tmpArray.forEach(regionindex => {
        const allregiondevice = []
        const camdevice = []
        const scaledevice = []
        regiondevicesArray.forEach(item => {
          if (item.region_key === regionindex.region_key) {
            if (
              item.region_device_devicetype !== 'smc' &&
              item.region_device_devicetype !== 'ipcam_pigweigh'
            ) {
              allregiondevice.push(item.region_devicelist)
            }
            if (item.region_device_devicetype === 'ipcam_pigweigh') {
              camdevice.push(item.region_devicelist)
            }
            // if (item.region_device_devicetype === 'scale') {
            //   scaledevice.push(item.region_devicelist)
            // }
          }
        })
        regiondeviceslist[regionindex.region_key] = allregiondevice
      })
    }
    // region
    const case_region_name_Array = []
    Object.keys(params.case_region).forEach(case_region_key => {
      const name = params.case_region[case_region_key].name
      const type = params.case_region[case_region_key].type
      case_region_name_Array.push({ case_region_key, name, type })
    })
    case_region_name_Array.sort((a, b) => {
      if (a.name < b.name) {
        return -1
      }
      if (a.name > b.name) {
        return 1
      }
      return 0
    })
    if (case_region_name_Array.length > 1) {
      showcategory_region = 'ALL'
    } else {
      showcategory_region = case_region_name_Array[0].case_region_key
    }
    return {
      deviceRegionCalculator,
      showcategory_region,
      regiondeviceslist,
      regiondevicesArray,
    }
  } catch (error) {
    console.log(error)
    return {}
  }
}
